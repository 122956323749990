import React from "react"
import AlertPageWrapper from "./src/components/elements/alert/alertPageWrapper.js"
import './src/theme/styles/global.css'

export const wrapPageElement = ({ element }) => {
  return (
    <AlertPageWrapper>
      {element}
    </AlertPageWrapper>
  )
}
