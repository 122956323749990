import React, { Children, useState } from "react"
import { AlertContext } from "./alertContext.js"

const AlertPageWrapper = ({children}) => {
  const [context, setContext] = useState(true)

  return (
    <AlertContext.Provider value={[context, setContext]}>
      {children}
    </AlertContext.Provider>
  )
}

export default AlertPageWrapper